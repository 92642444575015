import { TransportMainController } from "../transport_main_controller";

export class BillingController extends TransportMainController {

  //
  // Protected
  //
  _openModalForm(url) {
    super.openLayoutModalMD(url).then(() => {
      console.log('Modal was successfully opened.');
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  //
  // Private
  //
  get _sidebarTabulator() {
    let table_target = document.getElementById("transport--billing-sidebar-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  get _billingTabulator() {
    let table_target = document.getElementById("transport--billing-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  // Filters
  _getPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }
    return filters;
  }
}