import {BillingController} from "../../billing_controller";

export default class extends BillingController {
  editTransaction(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._openModalForm(url);
  }

  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._successNotify(data.notification_title, data.notification_message);
      super._hideOrUpdateModalContent(window.layout_modal_md, data);
      window.location.reload();
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._errorNotify(data.notification_title, data.notification_message);

      const error_message = data.error;
      const error_field = document.getElementById('form_transaction_errors')
      if (error_field) error_field.innerHTML = error_message;
    }
  }
}