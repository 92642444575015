import {ClientsController} from "../clients_controller";

export default class extends ClientsController {
  static targets = ["delete_link"]

  destroyClient(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._deleteClient(url);
  }

  //
  // Private
  //
  _deleteClient(url) {
    let confirmMessage = this.data.get("confirm-text");
    if (confirm(confirmMessage)) {
      Rails.ajax({
        url: url,
        type: 'delete',
        success: (data) => {
          if (data) {
            super._successNotify(data.notification_title, data.notification_message);
            this._removeRowFromTable(data.table_type, data);
          }
        },
        error: (data) => {
          if (data) {
            super._errorNotify(data.notification_title, data.notification_message);
          }
        }
      })
    }
  }

  _removeRowFromTable(table_type, data) {
    if (table_type === 'sidebar') {
      Turbolinks.visit(data.url);
    } else {
      super._deleteTabulatorRowById(this._activeTabulator, data.id);
    }
  }
}