import Tabulator from "tabulator-tables";
import {BillingController} from "../billing_controller";

export default class extends BillingController {
  static targets = [
    "search",
    // table
    "table", "orders_table",
    // sidebar_sort
    "sidebar_sort", "sidebar_current_sort",
    // orders filters
    "start_date", "end_date", "status", "only_not_paid"
  ]

  connect() {
    // Sidebar
    if (this.hasTableTarget) {
      this._createSidebarTable()
        .then(() => {
          console.log('Sidebar table was successfully created.');
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
    // Orders
    if (this.hasOrders_tableTarget) {
      this._createOrdersTable()
        .then(() => {
          console.log('Orders table was successfully created.');
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }

  // Sidebar Sort
  sidebarSort(event) {
    let sort_target = event.currentTarget;
    let sort_column = sort_target.dataset.sortColumn;
    let sort_dir = sort_target.dataset.sortDir;
    let table = this.tableTarget.tabulator;

    // change sort dir
    if (sort_dir === "asc") sort_dir = "desc"
    else sort_dir = "asc"

    sort_target.dataset.sortDir = sort_dir

    // set sort to table
    table.setSort(sort_column, sort_dir);

    // change links
    this.sidebar_sortTargets.forEach(el => {
      el.classList.remove("active");
      el.innerHTML = el.dataset.sortColumnName;
    });
    sort_target.classList.add("active");
    // set new icon
    const new_html = this._getSidebarSortHtml(sort_target.dataset.sortColumnName, sort_dir);
    sort_target.innerHTML = new_html;
    if (this.hasSidebar_current_sortTarget) this.sidebar_current_sortTarget.innerHTML = new_html;
  }

  // Sidebar Filter
  filterTable() {
    let filters = this._getPageFilters();
    this._setSidebarFilterToTable(filters).then(() => {
      console.log('Active table was successfully filtered.');
    }).catch(error => {
      console.error('Active table filtered Error:', error);
    });
  }

  // Orders Filter
  ordersFilterTable() {
    let filters = this._getOrdersFilters();
    this._setFilterToTable(this.orders_tableTarget.tabulator, filters).then(() => {
      console.log('Orders table was successfully filtered.');
    }).catch(error => {
      console.error('Orders table filtered Error:', error);
    });
  }
  //
  // Private
  //

  // Orders table
  async _createOrdersTable() {
    const table_target = this.orders_tableTarget;
    const columns_data = await this._getTableColumns();
    const sort_column = columns_data["sort_column"];
    const sort_dir = columns_data["sort_dir"];
    const columns = columns_data["columns"];
    const filters = this._getOrdersFilters();
    const url = this.data.get("orders-table-load-url");
    const lang = document.body.dataset.lang;
    const persistenceID = "transport_client_orders_table_" + lang;
    const height = document.getElementById('content').clientHeight;

    let table_props = super._buildTableProps(url, persistenceID, columns, sort_column, sort_dir, filters);
    let formatRow = this._orderFormatRow
    let footerTotals = this._footerTotals
    let footer_id = persistenceID + "_footer";
    let totals_html = "<div class='billing_orders_footer' id='" + footer_id + "'></div>";

    const props = {
      dataTree: true,
      dataTreeStartExpanded: false,
      height: (height - 270) + "px",
      footerElement: totals_html,
      rowFormatter: function (row) { formatRow(row) },
      ajaxResponse: function(url, params, response){
        let totals_html = response['totals']
        footerTotals(totals_html);
        return response;
      },
    }

    const orders_table_props = Object.assign(table_props, props);
    // create table
    table_target.tabulator = await new Tabulator(table_target, orders_table_props);
    await table_target.tabulator.setLocale(super.appLocale);
  }

  _footerTotals(html) {
    const lang = document.body.dataset.lang;
    let footer_id = "transport_client_orders_table_" + lang + "_footer";
    let footer = document.getElementById(footer_id);
    if (footer) {
      footer.innerHTML = html;
    }
  }

  // Orders table row format
  // if record type is transport_order show all columns
  // if record type is billing_transaction merge columns
  _orderFormatRow(row) {
    const data = row.getData();

    // if record type is billing_transaction add class to row
    if (data.record_type === "billing_transaction") {
      row.getElement().classList.add("billing-transaction");
    }
  }


  // fetch table columns
  // columns: {
  // columns: [
  // {
  // title: "№",
  // field: "index",
  // formatter: "html",
  // hozAlign: "left",
  // width: 50,
  // headerSort: false,
  // frozen: false,
  // responsive: 0
  // },
  // {
  // title: "Номер",
  // field: "orders__number",
  // headerSort: true,
  // formatter: "html",
  // hozAlign: "left",
  // visible: true,
  // headerHozAlign: "left",
  // frozen: false,
  // responsive: 0,
  // width: 90,
  // bottomCalc: "",
  // bottomCalcFormatter: "",
  // editor: false,
  // download: true
  // },
  // {
  // title: "Дата<br/>создания",
  // field: "orders__created_at",
  // headerSort: true,
  // formatter: "plaintext",
  // hozAlign: "left",
  // visible: true,
  // headerHozAlign: "left",
  // frozen: false,
  // responsive: 0,
  // width: 140,
  // bottomCalc: "",
  // bottomCalcFormatter: "",
  // editor: false,
  // download: true
  // },
  // {
  // title: "Дата<br/>начала и окончания",
  // field: "orders__start_date_and_end_date",
  // headerSort: true,
  // formatter: "html",
  // hozAlign: "left",
  // visible: true,
  // headerHozAlign: "left",
  // frozen: false,
  // responsive: 0,
  // width: 160,
  // bottomCalc: "",
  // bottomCalcFormatter: "",
  // editor: false,
  // download: true
  // },
  // {
  // title: "Маршрут",
  // field: "orders__services",
  // headerSort: false,
  // formatter: "html",
  // hozAlign: "left",
  // visible: true,
  // headerHozAlign: "left",
  // frozen: false,
  // responsive: 0,
  // width: 260,
  // bottomCalc: "",
  // bottomCalcFormatter: "",
  // editor: false,
  // download: true
  // },
  // {
  // title: "Количество<br/>пассажиров",
  // field: "orders__passengers_count",
  // headerSort: true,
  // formatter: "plaintext",
  // hozAlign: "left",
  // visible: true,
  // headerHozAlign: "left",
  // frozen: false,
  // responsive: 0,
  // width: 110,
  // bottomCalc: "",
  // bottomCalcFormatter: "",
  // editor: false,
  // download: true
  // },
  // {
  // title: "Стоимость<br/>итого",
  // field: "orders__total_amount",
  // headerSort: true,
  // formatter: "html",
  // hozAlign: "left",
  // visible: true,
  // headerHozAlign: "left",
  // frozen: false,
  // responsive: 0,
  // width: 130,
  // bottomCalc: "",
  // bottomCalcFormatter: "",
  // editor: false,
  // download: true
  // },
  // {
  // title: "Статус<br/>оплаты",
  // field: "orders__payment_state",
  // headerSort: true,
  // formatter: "html",
  // hozAlign: "left",
  // visible: true,
  // headerHozAlign: "left",
  // frozen: false,
  // responsive: 0,
  // width: 100,
  // bottomCalc: "",
  // bottomCalcFormatter: "",
  // editor: false,
  // download: true
  // },
  // {
  // title: "Статус",
  // field: "orders__status",
  // headerSort: true,
  // formatter: "html",
  // hozAlign: "left",
  // visible: true,
  // headerHozAlign: "left",
  // frozen: false,
  // responsive: 0,
  // width: 100,
  // bottomCalc: "",
  // bottomCalcFormatter: "",
  // editor: false,
  // download: true
  // },
  // {
  // title: "Действия",
  // field: "orders__actions",
  // headerSort: false,
  // formatter: "html",
  // hozAlign: "center",
  // visible: true,
  // headerHozAlign: "left",
  // frozen: false,
  // responsive: 0,
  // width: 160,
  // bottomCalc: "",
  // bottomCalcFormatter: "",
  // editor: false,
  // download: false
  // }
  // ],
  // sort_column: "orders__passengers_count",
  // sort_dir: "asc"

  async _getTableColumns() {
    const url = this.data.get("orders-table-load-url");
    const response = await fetch(url);
    let data = [];
    if (response.ok) {
      data = await response.json();
    } else {
      console.error('Error:', response.status);
    }

    return data["columns"];
  }

  // Orders filters
  _getOrdersFilters() {
    let filters = []

    if (this.hasStart_dateTarget) {
      filters.push({
        field: "start_date",
        type: "=",
        value: this.start_dateTarget.value
      })
    }

    if (this.hasEnd_dateTarget) {
      filters.push({
        field: "end_date",
        type: "=",
        value: this.end_dateTarget.value
      })
    }

    let statusValues  = [];

    this.statusTargets.forEach((status) => {
      if (status.checked) {
        statusValues.push(status.value);
      }
    });

    if (statusValues.length > 0) {
      filters.push({
        field: "statuses",
        type: "=",
        value: statusValues
      })
    }

    if (this.hasOnly_not_paidTarget && this.only_not_paidTarget.checked) {
      filters.push({
        field: "only_not_paid",
        type: "=",
        value: true
      })
    }


    return filters;
  }

  // End Orders table
  async _setSidebarFilterToTable(filters) {
    let tabulator = this.tableTarget.tabulator;
    if (!this.hasTableTarget || !tabulator) return;
    await this._setFilterToTable(tabulator, filters).then(() => {
      console.log('Sidebar table was successfully filtered.');
    }).catch(error => {
      console.error('Sidebar table filtered Error:', error);
    });
  }

  // gen icon html
  _getSidebarSortHtml(column_name, sort_dir) {
    let up_or_down = "up";
    if (sort_dir === "asc") up_or_down = "down"
    return column_name + "&nbsp; <i class='fa-sort-alpha-" + up_or_down + " fas sort_dir'></i>";
  }

  // Create Sidebar table
  async _createSidebarTable() {
    const table_target = this.tableTarget;
    let table_props = this._buildTableProps;
    //
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  get _buildTableProps() {
    const columns = this.data.get("table-columns");
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    //
    const url = this.data.get("table-load-url");
    //
    let formatRow = super._defaultFormatRow
    let rowClick = super._defaultRowClick
    //
    let props = {
      columns: JSON.parse(columns),
      // sort
      initialSort: [{ column: sort_column, dir: sort_dir }],
      initialFilter: this._getPageFilters(),
      //
      rowFormatter: function (row) { formatRow(row) },
      //
      rowClick: function (e, row) { rowClick(e, row) }
    }
    //
    return Object.assign(super._getDefaultSidebarTableProps(url), props);
  }
}