import BSN from "bootstrap.native";
import {ClientsController} from "../clients_controller";

export default class extends ClientsController {
  static targets = [
    // filters
    "search", "archive", "sales_channel_ids",
    // tabs and tables
    "active_table",
    // mapping
    "mapping_dropdown", "mapping_checkbox"
  ]

  connect() {
    if (this.hasActive_tableTarget) {
      this._crateActiveTable().then(() => {
        console.log('Active table was successfully created.');
      }).catch(error => {
        console.error('Active table Error:', error);
      });
    }
  }

  //
  // Actions
  //
  // table filters action
  filterTable(event) {
    if (event.currentTarget === this.searchTarget) {
      if ( event.currentTarget.value.length < 3 && event.currentTarget.value.length !== 0 ) {
        return;
      }
    }

    let filters = this._getPageFilters();

    // Change dataset filtersChanged to true
    super._filtersChangedForTable(this.active_tableTarget);

    this._filterActiveTable(filters).then(() => {
      console.log('Active table was successfully filtered.');
    }).catch(error => {
      console.error('Active table filtered Error:', error);
    });

    if (filters.length > 0) {
      this._filtersToHistoryUrl(filters);
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }
  //
  //            PRIVATE
  //
  async _filterActiveTable(filters) {
    let activeTabulator = this.active_tableTarget.tabulator;
    if (!activeTabulator) return;

    await this._setFilterToTable(activeTabulator, filters).then(() => {
      console.log('Active table was successfully filtered.');
    }).catch(error => {
      console.error('Active table filtered Error:', error);
    });
  }

  async _crateActiveTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const filters = this._getPageFilters();
    const url = this.data.get("active-table-load-url");
    const lang = document.body.dataset.lang;
    const persistenceID = "transport_client_active_table_" + lang;

    await this._createTable(
      url,
      this.active_tableTarget,
      persistenceID,
      columns,
      sort_column,
      sort_dir,
      filters
    )
  }
}