import {ClientsController} from "../clients_controller";
import { getMetaValue } from "helpers";


export default class extends ClientsController {
  static targets = [
    "content",
    "discount_type_info",
    "discount_type_form",
    "discount_type_input",
    "discount_value_input",
    "discount_type_edit_link",
    "discount_type_button"
  ]

  connect() {
    console.log('Tariffs Content controller was successfully connected.');
    if (this.hasContentTarget) {
      console.log('Tariffs Content controller has content target.');
      const content_url = this.data.get("content-load-url");
      const element_id = this.data.get("activate-element-id");
      const element = document.getElementById(element_id)
      const controller = this;
      element.addEventListener("click", function handler(event) {

        console.log('Tariffs Content controller content load url:', content_url);
        controller._createContent(content_url)
          .catch(error => console.error('Error:', error));


        // element.removeEventListener("click", handler);
      });
    }
  }

  editTariff(event) {
    event.preventDefault();
    this.discount_type_infoTarget.classList.add("hidden");
    this.discount_type_edit_linkTarget.classList.add("hidden");
    this.discount_type_formTarget.classList.remove("hidden");
    this.discount_type_buttonTarget.classList.remove("hidden");
    this.discount_type_inputTarget.focus();
  }

  saveTariff(event) {
    event.preventDefault();

    this._saveTariff().then(() => {
      console.log('Tariff was successfully saved.');
    }).catch(error => {
      console.error('Error:', error);
    });
  }


  // PRIVATE
  async _saveTariff() {
    const url = this.element.dataset.saveUrl;
    console.log('Save tariff url:', url);

    const data = {
      discount_type: super._getSelectCollectionValues(this.discount_type_inputTarget),
      discount_value: this.discount_value_inputTarget.value
    };
    const response = await fetch(url, {
      method: "POST",
      dataType: "text/javascript",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
      body: JSON.stringify(data)
    });
    if (response.ok) {
      this.discount_type_infoTarget.innerHTML = this.discount_type_inputTarget.value;
      this.discount_type_infoTarget.classList.remove("hidden");
      this.discount_type_edit_linkTarget.classList.remove("hidden");
      this.discount_type_buttonTarget.classList.add("hidden");
      this.discount_type_formTarget.classList.add("hidden");

      const contentTarget = document.getElementById("client_tab_tariffs");
      const content_url = contentTarget.dataset['transport-Clients-TariffsContentLoadUrl'];
      this._createContent(content_url)
        .catch(error => console.error('Error:', error));
    } else {
      console.error('Error:', response.statusText);
    }
  }


  async _createContent(url) {
    const contentTarget = document.getElementById("client_tab_tariffs");
    contentTarget.innerHTML = this.spinner;
    let content_response = await fetch(url);
    contentTarget.innerHTML = await content_response.text();
  }

  get spinner() {
    return `
      <div class="text-center mt-50">
        <div class="loader"></div>
      </div>
    `;
  }
}