import BSN from "bootstrap.native";
import {BillingController} from "../billing_controller";

export default class extends BillingController {
  static targets = [
    // filters
    "search",
    // tabs and tables
    "table",
    // mapping
    "mapping_dropdown", "mapping_checkbox"
  ]

  connect() {
    if (this.hasTableTarget) {
      this._createBillingTable().then(() => {
        console.log('_createBillingTable table was successfully created.');
      }).catch(error => {
        console.error('_createBillingTable table Error:', error);
      });
    }
  }

  //
  // Actions
  //
  // table filters action
  filterTable(event) {
    if (event.currentTarget === this.searchTarget) {
      if ( event.currentTarget.value.length < 3 && event.currentTarget.value.length !== 0 ) {
        return;
      }
    }

    let filters = this._getPageFilters();

    this._filterBillingTable(filters).then(() => {
      console.log('_filterBillingTable table was successfully filtered.');
    }).catch(error => {
      console.error('_filterBillingTable table filtered Error:', error);
    });

    if (filters.length > 0) {
      this._filtersToHistoryUrl(filters);
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }
  //
  //            PRIVATE
  //
  async _filterBillingTable(filters) {
    let tabulator = this._billingTabulator;
    if (!tabulator) return;

    await this._setFilterToTable(tabulator, filters).then(() => {
      console.log('tabulator table was successfully filtered.');
    }).catch(error => {
      console.error('tabulator table filtered Error:', error);
    });
  }

  async _createBillingTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const filters = this._getPageFilters();
    const url = this.data.get("table-load-url");
    const lang = document.body.dataset.lang;
    const persistenceID = "transport_billing_table_" + lang;

    await this._createTable(
      url,
      this.tableTarget,
      persistenceID,
      columns,
      sort_column,
      sort_dir,
      filters
    )
  }
}