import * as XLSX from 'xlsx-js-style';
import Tabulator from "tabulator-tables";
import {ClientsController} from "../clients_controller";

window.XLSX = XLSX;

export default class extends ClientsController {
  static targets = [
    "form",
    "modal_body",
    "send_form_button",
    "table",
    "not_created_table",
    'name',
    'address',
    'phone',
    'email',
    'website',
    'sales_channel',
    'tax_id',
    'bank_name',
    'bank_account',
    'mfo',
    'bank_tax_id',
    'contact_full_name',
    'contact_phone',
    'contact_email'
  ]

  newImport(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    super.openLayoutModalXL(url).then(() => {
      console.log('Modal was successfully opened.');
    }).catch(error => {
      console.error('Error:', error);
    });

  }

  fileSelect(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContent = e.target.result;
      const data = new Uint8Array(fileContent);
      const workbook = XLSX.read(data, {type: 'array'});
      const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
      this.createTable(this.tableTarget, jsonData);
    };
    reader.readAsArrayBuffer(file);
  }

  sendForm(event) {
    event.preventDefault();
    const url = this.data.get("form-url");
    const formData = this._makeFormData();

    if (!this._validateForm()) return;

    // disable form_button
    this.send_form_buttonTarget.disabled = true;

    Rails.ajax({
      url: url,
      type: 'post',
      data: formData,
      contentType: 'application/json',
      dataType: 'json',
      success: (data) => {
        if (data) {
          this._showImportResult(data);
          this._reloadMainTable();
        }
      },
      error: (data) => {
        if (data) {
          super._errorNotify(data.notification_title, data.notification_message);
        }
      }
    })
  }

  // Private
  _reloadMainTable() {
    const table = super._activeTabulator;
    if (table) { table.setData(); }
  }

  _showImportResult(data) {
    super._successNotify(data.notification_title, data.notification_message);
    this.modal_bodyTarget.innerHTML = data.form_content;
    const table_target = document.getElementById('transport-clients-import-not-created-table');
    const table_data = JSON.parse(table_target.dataset.rows);
    if (table_data.length !== 0) { this.createTable(table_target, table_data, true); }


    const buttons= document.getElementById('import-modal-footer');
    buttons.innerHTML = data.buttons;
  }

  // validate
  _validateForm() {
    const formController = this.application.getControllerForElementAndIdentifier(this.formTarget, "layout--form");
    return formController.validateForm();
  }
  _makeFormData() {
    const import_data = this.tableTarget.tabulator.getData();
    const columns_keys= {
      name: this.nameTarget.choices.getValue(true) || '',
      address: this.addressTarget.choices.getValue(true) || '',
      phone: this.phoneTarget.choices.getValue(true) || '',
      email: this.emailTarget.choices.getValue(true) || '',
      website: this.websiteTarget.choices.getValue(true) || '',
      sales_channel: this.sales_channelTarget.choices.getValue(true) || '',
      tax_id: this.tax_idTarget.choices.getValue(true) || '',
      bank_name: this.bank_nameTarget.choices.getValue(true) || '',
      bank_account: this.bank_accountTarget.choices.getValue(true) || '',
      mfo: this.mfoTarget.choices.getValue(true) || '',
      bank_tax_id: this.bank_tax_idTarget.choices.getValue(true) || '',
      contact_full_name: this.contact_full_nameTarget.choices.getValue(true) || '',
      contact_phone: this.contact_phoneTarget.choices.getValue(true) || '',
      contact_email: this.contact_emailTarget.choices.getValue(true) || ''
    }
    const params = {
      import_data: import_data,
      columns_keys: columns_keys
    }

    return new URLSearchParams(this._recursiveEntries(params)).toString();
  }

  createTable(table_target, data, is_result = false) {
    let columns = Object.keys(data[0]).map(key => {
      return { title: key, field: key };
    });


    // result true change errors column format
    if (is_result) {
      let errorColumnIndex = columns.findIndex(column => column.title === 'errors');
      if (errorColumnIndex !== -1) {
        columns[errorColumnIndex] = this._buildErrorsColumn();
      }
    }

    if (!is_result) {
      // delete button
      columns.unshift({
        title: '',
        formatter: 'buttonCross',
        width: 40,
        hozAlign: 'center',
        cellClick: function (e, cell) {
          cell.getRow().delete();
        }
      });
    }

    // create table
    table_target.tabulator = new Tabulator(table_target, {
      data: data,
      columns: columns,
      maxHeight: "500px"
    });

    if (!is_result) {
      // add options to select
      this._addOptionsToFormSelects(columns);
      // enable form_button
      this.send_form_buttonTarget.disabled = false;
    }
  }

  _buildErrorsColumn() {
    const errorFormatter = function(cell, formatterParams, onRendered) {
      const value = cell.getValue();
      const errorsTranslate = {
        name: 'Название',
        address: 'Адрес',
        phone: 'Телефон',
        email: 'Email',
        website: 'Сайт',
        sales_channel_id: 'Канал продаж',
        tax_id: 'ИНН',
        bank_name:  'Название банка',
        bank_account: 'Счет банка',
        mfo: 'МФО',
        bank_tax_id: 'ИНН банка',
        full_name: 'Контактное лицо',
        contact_phone: 'Телефон контактного лица',
        contact_email: 'Email контактного лица'
      }
      if (typeof value === 'object' && value !== null) {
        const ul = document.createElement('ul');
        for (const errorKey in value) {
          if (value.hasOwnProperty(errorKey)) {
            for (const error of value[errorKey]) {
              const li = document.createElement('li');
              const errorKeyTranslate = errorsTranslate[errorKey] || errorKey;
              li.textContent = `${errorKeyTranslate}: ${error}`;
              ul.appendChild(li);
            }
          }
        }
        return ul;
      }
      return value;
    };

    return {
      title: 'Ошибки',
      field: 'errors',
      formatter: errorFormatter,
      width: 400,
      hozAlign: 'left',
      headerSort: false
    };
  }

  _addOptionsToFormSelects(columns) {
    const choices = columns.map(column => {
      return {value: column.field, label: column.title}
    });
    this.nameTarget.choices.setChoices(choices, 'value', 'label', true);
    this.addressTarget.choices.setChoices(choices, 'value', 'label', true);
    this.phoneTarget.choices.setChoices(choices, 'value', 'label', true);
    this.emailTarget.choices.setChoices(choices, 'value', 'label', true);
    this.websiteTarget.choices.setChoices(choices, 'value', 'label', true);
    this.sales_channelTarget.choices.setChoices(choices, 'value', 'label', true);
    this.tax_idTarget.choices.setChoices(choices, 'value', 'label', true);
    this.bank_nameTarget.choices.setChoices(choices, 'value', 'label', true);
    this.bank_accountTarget.choices.setChoices(choices, 'value', 'label', true);
    this.mfoTarget.choices.setChoices(choices, 'value', 'label', true);
    this.bank_tax_idTarget.choices.setChoices(choices, 'value', 'label', true);
    this.contact_full_nameTarget.choices.setChoices(choices, 'value', 'label', true);
    this.contact_phoneTarget.choices.setChoices(choices, 'value', 'label', true);
    this.contact_emailTarget.choices.setChoices(choices, 'value', 'label', true);
  }

  _recursiveEntries(obj, parentKey = '') {
    let entries = [];
    for (let [key, value] of Object.entries(obj)) {
      let newKey = parentKey ? `${parentKey}[${key}]` : key;
      if (typeof value === 'object' && value !== null) {
        entries.push(...this._recursiveEntries(value, newKey));
      } else {
        entries.push([newKey, value]);
      }
    }
    return entries;
  }
}